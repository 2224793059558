<template>
  <div class="app-container">
    <el-form :model="companyInfoModel"
             :rules="rules"
             ref="companyInfoFrom"
             label-width="150px"
             size="small">
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业名称：" >
            <el-input v-model="companyInfoModel.companyName" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="统一社会信用代码：" >
            <el-input v-model="companyInfoModel.creditCode" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="工商注册详细地址：" >
            <el-input v-model="companyInfoModel.businessRegistrationDetailedAddress" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="工商所在地：" >
            <el-input v-model="companyInfoModel.businessLocation" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="生产设施地址：" >
            <el-input v-model="companyInfoModel.productionFacilityAddress" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业法人：" >
            <el-input v-model="companyInfoModel.enterpriseLegalPerson" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="年产危废规模：" >
            <el-input v-model="companyInfoModel.annualProductionScale" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="管辖环保局：" >
            <el-input v-model="companyInfoModel.responsibleProtectionBureau" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="注册资金（万元）：" >
            <el-input v-model="companyInfoModel.registeredCapital" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="面积（万平方）：" >
            <el-input v-model="companyInfoModel.area" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="环保负责人：" >
            <el-input v-model="companyInfoModel.superintendent" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话：" >
            <el-input v-model="companyInfoModel.telephone" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="传真：" >
            <el-input v-model="companyInfoModel.fax" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱：" >
            <el-input v-model="companyInfoModel.email" style="width: 80%" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="营业执照：" v-if="!companyInfoModel.businessLicense">
            <el-upload ref="uploadForm" v-model="companyInfoModel.businessLicense" drag
                       action="#"
                       :file-list="fileListShow"
                       :auto-upload="false"
                       :before-remove="handleRemove"
                       :on-change="fileChange"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="执照预览：" v-if="!companyInfoModel.businessLicense">
            <img :src="previewPath" alt="" style="width:80%;"  @click="handlePictureEnlarge(previewPath)"   />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="companyInfoModel.businessLicense" >
          <el-form-item  label="已传图片：">
            <img style="width: 80%" :src="companyInfoModel.businessLicense"  class="avatar"  @click="handlePictureEnlarge(companyInfoModel.businessLicense)"  >
            <span @click="handleDeleteLicense">删除</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style=";width: 500px;margin: 10px auto;">
        <el-button style="width: 500px;" type="primary" @click="handleDialogConfirm('companyInfoFrom')">提交</el-button>
      </el-row>
    </el-form>

    <el-dialog v-model="dialogVisiblePic" title="图片">
      <img :src="enlargedImageUrl" alt="Enlarged Image" style="max-width: 100%; display: block; margin: auto;">
    </el-dialog>

  </div>


</template>

<script>

import {
  companyInfoList,
  updateCompanyInfo,
  createCompanyInfo,
} from "@/api/companyInfo";

const defaultListQuery = {
  judge: "own",
};
const defaultCompanyInfo = {
  companyName: "",
  creditCode: "",
  businessRegistrationDetailedAddress: "",
  businessLocation: "",
  productionFacilityAddress: "",
  enterpriseLegalPerson: "",
  businessLicense: "",
  editBusinessLicense: "",
  annualProductionScale: "",
  responsibleProtectionBureau: "",
  registeredCapital: "",
  area: "",
  superintendent: "",
  telephone: "",
  fax: "",
  email: "",
};


export default {
  name: 'companyInfo',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      listLoading: false,
      isEditFlag: false,
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
      },
      companyInfoModel: Object.assign({}, defaultCompanyInfo),

      //营业执照
      fileListShow:[],
      fileList:[],
      previewPath: '', // 预览路径
      dialogVisiblePic: false,
      enlargedImageUrl: '',
      //营业执照



    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //放大预览
    handlePictureEnlarge(imageUrl) {
      this.enlargedImageUrl = imageUrl;
      this.dialogVisiblePic = true;
    },
    //文件上传
    fileChange(file, fileList) {
      this.fileList = [];
      this.fileList.push(file);
      this.fileListShow = [];
      this.fileListShow.push({
        name: file["name"],
      })
      //如果上传的图片没有url字段，手动生成
      if (!file.url) {
        this.previewPath =  URL.createObjectURL(file.raw);
      }
    },
    //文件删除
    handleRemove(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileList = this.fileList.filter(item => item.name !== file.name);
    },
    //删除营业执照
    handleDeleteLicense(){
      this.$confirm('确定清空已传营业执照吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        //删除附件
        this.companyInfoModel.editBusinessLicense = this.companyInfoModel.businessLicense;
        this.companyInfoModel.businessLicense = "";

        this.$nextTick(() => {
          if (this.$refs['uploadForm']) {
            this.$refs['uploadForm'].clearFiles()  // 清除历史文件列表
          }
        })
        this.fileListShow=[];
        this.fileList=[];
        this.previewPath=''; // 预览路径
        this.dialogVisiblePic= false;
        this.enlargedImageUrl= '';

        this.$forceUpdate();   // 强制更新
      });
    },

    //列表
    getTableList() {
      this.listLoading = true;
      companyInfoList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          if(res.data == null){
            this.companyInfoModel = Object.assign({}, defaultCompanyInfo);
            this.isEditFlag = false;
          }else{
            this.companyInfoModel = res.data;
            this.isEditFlag = true;
          }
        }
      })
    },

    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {

            let formData = new FormData();
            for (let key in this.companyInfoModel) {
              formData.append(key, this.companyInfoModel[key]);
            }

            for (let i = 0; i < this.fileList.length; i++) {
              formData.append('myFile', this.fileList[i].raw);
            }

            if (this.isEditFlag) {
              updateCompanyInfo(formData).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '修改成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createCompanyInfo(formData).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '提交成功',
                    type: 'success',
                  });*/
                  this.dialogVisible =false;
                  this.getTableList();
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }

              });
            }
          });
        }
      });
    },

  }
}
</script>
